import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect, useDispatch } from 'react-redux'
import React, { createContext, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'

import {
  login,
  setVoucherValidateError,
  setVoucherValidateType,
  setSignupFormValue
} from '../../../actions'
import { useApiClient } from './use-api-client'
import { FORM_MESSAGES, MODALS } from '../../../constants'
import { STORAGE_KEY_VOUCHER_APPLICABLE_PLANS } from '../../../storage-keys'
import { checkIsAuthenticated } from '../../../lib/auth'
import { usePlanChangeFlag } from '../../../hooks/usePlanChange'
import { segmentIdentify } from '../../../segment/segment-identify'

const AuthContext = createContext(null)

/**
 * Provides authentication context from redux
 *
 * @param {boolean} isAuthenticated
 * @param {object} session
 * @param {function} dispatch
 */
const AuthProvider = ({
  isAuthenticated, session, dispatch, children
}) => (
  <AuthContext.Provider
    value={{
      isAuthenticated,
      session,
      dispatch
    }}
  >
    {children}
  </AuthContext.Provider>
)

AuthProvider.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    token: PropTypes.string,
    decodedJwt: PropTypes.shape({
      sub: PropTypes.string,
      email: PropTypes.string,
      first_name: PropTypes.string
    })
  }),
  dispatch: PropTypes.func
}

/**
 * Consumes the authentication context to provide user information
 * and other authentication functions
 */
export const useAuth = () => {
  const ctx = useContext(AuthContext)
  const api = useApiClient()
  const history = useHistory()
  const location = useLocation()
  const dispatchState = useDispatch()
  const getPlanDetails = usePlanChangeFlag()

  const SKU_ARRAY = [
    getPlanDetails.basic,
    getPlanDetails.standard,
    getPlanDetails.annual
  ]

  if (ctx === null) throw new Error('useAuth() can only be used inside an <AuthProvider />')

  let user = null
  let token = null

  if (ctx.session && ctx.session.token) {
    token = ctx.session.token
    user = {
      id: ctx.session.decodedJwt.sub,
      email: ctx.session.decodedJwt.email,
      firstName: ctx.session.decodedJwt.first_name
    }
  }

  const getPlanTitleBySku = (sku) => {
    return SKU_ARRAY.find(item => item.sku === sku).title || ''
  }

  const getSkuByPlanType = (type) => {
    return SKU_ARRAY.find(item => item.type === type).sku || ''
  }

  const validateVoucherApplicable = async (voucher, activeSubscription) => {
    try {
      await api.validateVoucherApplicable(voucher, getSkuByPlanType(activeSubscription))
      dispatchState(setVoucherValidateError(''))
      dispatchState(setVoucherValidateType(''))
      return {
        success: true
      }
    } catch (error) {
      if (error.name === 'VoucherApplicableError') {
        dispatchState(setVoucherValidateError(error.message))
        dispatchState(setVoucherValidateType(getPlanTitleBySku(error.voucherSku)))
        // remove voucher from url
        const searchQuery = { ...qs.parse(location.search) }
        delete searchQuery.voucherCode
        searchQuery.plan = activeSubscription
        // Show error modal
        searchQuery.modal = MODALS.qsParams.voucherValidateErrorForSignup
        history.replace({
          ...location,
          search: qs.stringify(searchQuery)
        })
      }
      return {
        success: false,
        errors: {
          voucherCode: 'VoucherApplicableError',
          formError: error.message
        }
      }
    }
  }

  const createAccount = async payload => {
    try {
      const { dispatch } = ctx
      const { voucherCode } = payload

      // Clear the voucher info in session storage
      sessionStorage.removeItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS)

      if (voucherCode && voucherCode !== '') {
        // validate voucher then save voucher for plan selection
        const result = await api.validateVoucherWithoutSku(voucherCode)

        if (result.applicablePlans) {
          sessionStorage.setItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS, result.applicablePlans?.toString())
        }
      }

      const { account } = await api.createAccount(payload)
      // Add segment data identify for sign up
      const currentProfile = account.profiles?.find(item => item.id === account.selectedProfile)
      segmentIdentify(account, currentProfile)

      // Update sign up form values
      const initFormValues = {
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        voucherCode: '',
        birthYear: null,
        gender: ''
      }

      dispatch(setSignupFormValue(initFormValues))
      dispatch(login(account, true))
      dispatchState(setVoucherValidateError(''))
      dispatchState(setVoucherValidateType(''))
      return { success: true }
    } catch (error) {
      if (error.name === 'VoucherError') {
        // remove voucher from url
        const searchQuery = { ...qs.parse(location.search) }
        delete searchQuery.voucherCode
        history.replace({
          ...location,
          search: qs.stringify(searchQuery)
        })

        return {
          success: false,
          errors: {
            voucherCode: error.message,
            formError: FORM_MESSAGES.voucherInvalid
          }
        }
      }

      if (error.name === 'UserExistsError') {
        return {
          success: false,
          errors: {
            userExists: error.message,
            formError: error.message
          }
        }
      }

      return {
        success: false,
        errors: {
          formError: error.message
        }
      }
    }
  }

  return {
    user,
    token,
    createAccount,
    validateVoucherApplicable,
    dispatch: ctx.dispatch,
    isAuthenticated: ctx.isAuthenticated
  }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: checkIsAuthenticated(state),
      session: state.session
    }),
    dispatch => ({ dispatch })
  )
)(AuthProvider)
