/**
 * Specific error type for issues with the user's voucher code.
 */
export default class VoucherApplicableError extends Error {
  constructor(message, voucherSku) {
    super(message)
    this.name = 'VoucherApplicableError'
    this.voucherSku = voucherSku
  }
}
