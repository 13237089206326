import React, { createContext, useContext } from 'react'
import { withApollo } from 'react-apollo'
import { path, pathEq } from 'ramda'
import PropTypes from 'prop-types'

import VoucherError from '../../signup/lib/voucher-error'
import VoucherApplicableError from '../../signup/lib/voucher-applicable-error'
import UserExistsError from '../../signup/lib/user-exists-error'
import { SUBSCRIPTION_STATUS, VOUCHERS, MW_ERRORS } from '../../../constants'
import { getGQLErrorCode, getGQLErrorMsg, getGQLErrorDetails } from '../../../lib/apollo'

import VOUCHER_QUERY from '../../../../graphql/queries/voucher.gql'
import VOUCHER_WITH_SKU_QUERY from '../../../../graphql/queries/voucher-with-sku.gql'
import VOUCHER_WITHOUT_SKU_QUERY from '../../../../graphql/queries/voucher-without-sku.gql'
import ACCOUNT_MUTATION from '../../../../graphql/mutations/account.gql'
import ACCOUNT_QUERY_WITH_DIR from '../../../../graphql/queries/account-with-directives.gql'
import SUBSCRIPTION_PAYMENT_VIEW_QUERY
  from '../../../../graphql/queries/subscription-payment-view.gql'
import SUBSCRIPTION_CONFIRMATION_VIEW_QUERY
  from '../../../../graphql/queries/subscription-confirmation-view.gql'
import ACCOUNT_CREDIT_CARDS_QUERY from '../../../../graphql/queries/account-credit-cards.gql'
import CREDITCARD_ADD from '../../../../graphql/queries/creditcard-add.gql'

const ApiContext = createContext(null)

const ApiContextProvider = ({ client, children }) => (
  <ApiContext.Provider value={client}>{children}</ApiContext.Provider>
)

ApiContextProvider.propTypes = {
  client: PropTypes.shape({
    query: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired
  }).isRequired
}

/**
 * Provides functions to retrieve data or perform specific
 * operations on the middleware.
 */
export const useApiClient = () => {
  const client = useContext(ApiContext)

  if (client === null) {
    throw new Error(
      'useApiClient() can only be used inside an <ApiContextProvider />'
    )
  }

  const refreshUserAccount = async () => {
    try {
      const account = await client.query({
        query: ACCOUNT_QUERY_WITH_DIR,
        fetchPolicy: 'network-only',
        variables: {
          withCreditcards: true,
          withSubscription: true
        }
      })
      return account
    } catch (error) {
      const errorCode = getGQLErrorCode(error)
      throw new Error(errorCode)
    }
  }

  const doesUserHaveActiveCreditCards = async () => {
    try {
      const result = await client.query({
        query: ACCOUNT_CREDIT_CARDS_QUERY,
        options: {
          fetchPolicy: 'network-only',
          notifyOnNetworkStatusChange: true
        }
      })
      return path(['data', 'account', 'creditcards'], result)
    } catch (error) {
      const errorCode = getGQLErrorCode(error)
      throw new Error(errorCode)
    }
  }

  const doesUserHaveAnActiveSubscription = async () => {
    try {
      // TODO: Assess and switch out to a leaner query.
      //   AM: The reason we use this fat query is that it has a
      //       high likelyhood of being cached as it's used everywhere else.
      const response = await client.query({
        query: ACCOUNT_QUERY_WITH_DIR,
        fetchPolicy: 'cache-first',
        variables: {
          withCreditcards: true,
          withSubscription: true
        }
      })

      return pathEq(
        ['data', 'account', 'subscription', 'status'],
        SUBSCRIPTION_STATUS.ACTIVE
      )(response)
    } catch (error) {
      const errorCode = getGQLErrorCode(error)
      throw new Error(errorCode)
    }
  }

  const validateVoucher = async voucherCode => {
    try {
      if (!voucherCode) throw new Error('No voucher code provided')

      const response = await client.query({
        query: VOUCHER_QUERY,
        variables: {
          code: voucherCode
        }
      })

      if (
        !response.data.voucher ||
        (response.data.voucher &&
          response.data.voucher.type !== VOUCHERS.types.SVOD)
      ) {
        throw new Error(
          'Invalid voucher: Only subscription vouchers are accepted.'
        )
      } else {
        return response.data.voucher
      }
    } catch (error) {
      const errorMessage = error.graphQLErrors
        ? getGQLErrorMsg(error)
        : error.message

      throw new VoucherError(errorMessage)
    }
  }

  const validateVoucherWithoutSku = async voucherCode => {
    try {
      if (!voucherCode) throw new Error('No voucher code provided')

      const response = await client.query({
        query: VOUCHER_WITHOUT_SKU_QUERY,
        variables: {
          voucherCode
        }
      })

      if (
        !response.data.voucherWithoutSku ||
        (response.data.voucherWithoutSku &&
          response.data.voucherWithoutSku.type !== VOUCHERS.types.SVOD)
      ) {
        throw new Error(
          'Invalid voucher: Only subscription vouchers are accepted.'
        )
      } else {
        return response.data.voucherWithoutSku
      }
    } catch (error) {
      const errorMessage = error.graphQLErrors
        ? getGQLErrorMsg(error)
        : error.message

      throw new VoucherError(errorMessage)
    }
  }

  const validateVoucherApplicable = async (voucherCode, sku) => {
    try {
      if (!voucherCode) throw new Error('No voucher code provided')

      const response = await client.query({
        query: VOUCHER_WITH_SKU_QUERY,
        variables: {
          voucherCode,
          signupSku: sku
        }
      })

      if (!response.data.voucherWithSku) {
        throw new Error(
          'Invalid voucher: Only subscription vouchers are accepted.'
        )
      }
    } catch (error) {
      const errorCode = error.graphQLErrors && getGQLErrorCode(error)
      const errorDetails = error.graphQLErrors && getGQLErrorDetails(error)
      const errorMessage = error.graphQLErrors
        ? getGQLErrorMsg(error)
        : error.message

      if (errorCode === MW_ERRORS.VOUCHER_NOT_APPLICABLE) {
        throw new VoucherApplicableError(errorMessage, errorDetails?.voucherSku)
      }
      throw new VoucherError(errorMessage)
    }
  }

  const createAccount = async payload => {
    try {
      const response = await client.mutate({
        mutation: ACCOUNT_MUTATION,
        variables: {
          input: {
            name: payload.firstName,
            surname: payload.lastName,
            email: payload.email,
            password: payload.password,
            birthYear: payload.birthYear,
            gender: payload.gender,
            optIns: [
              {
                id: 'RECEIVE_UPDATES',
                subscribed: payload.optInToNewsletter
              }
            ]
          }
        }
      })

      return response.data
    } catch (error) {
      const errorCode = error.graphQLErrors && getGQLErrorCode(error)
      const errorMessage = error.graphQLErrors
        ? getGQLErrorMsg(error)
        : error.message

      if (
        errorCode &&
        ['USER_EXISTS', 'INVALID_CREDENTIALS'].includes(errorCode)
      ) {
        throw new UserExistsError(errorMessage)
      }

      throw new Error(errorMessage)
    }
  }

  const buildSubscriptionPaymentViewQuery = (
    subscriptionId,
    voucherCode,
    enablePromotions,
    bundleType
  ) => {
    return {
      query: SUBSCRIPTION_PAYMENT_VIEW_QUERY,
      variables: {
        subscriptionId,
        voucher: voucherCode,
        promotions: enablePromotions,
        bundleType
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  }

  const getSubscriptionPaymentDetails = async (subscriptionId, voucherCode, enablePromotion, bundleType) => {
    try {
      const result = await client.query(
        buildSubscriptionPaymentViewQuery(subscriptionId, voucherCode, enablePromotion, bundleType)
      )
      return {
        iframe: path(['data', 'subscriptionPaymentView', 'iframe'], result),
        details: path(
          ['data', 'subscriptionPaymentView', 'details'],
          result
        )
      }
    } catch (error) {
      const errorCode = getGQLErrorCode(error)
      throw new Error(errorCode)
    }
  }

  const getSubscriptionConfirmationDetails = async orderId => {
    try {
      const response = await client.query({
        query: SUBSCRIPTION_CONFIRMATION_VIEW_QUERY,
        variables: {
          orderId,
          paymentMethodId: ''
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      })

      const details = path(
        ['data', 'subscriptionConfirmationView', 'details'],
        response
      )

      return details
    } catch (error) {
      const errorCode = getGQLErrorCode(error)
      throw new Error(errorCode)
    }
  }

  const addPaymentMethod = async () => {
    try {
      const res = await client.query({
        query: CREDITCARD_ADD,
        fetchPolicy: 'network-only'
      })
      return path(['data', 'creditcardAdd'], res)
    } catch (error) {
      const errorCode = getGQLErrorCode(error)
      throw new Error(errorCode)
    }
  }

  return {
    createAccount,
    validateVoucher,
    validateVoucherWithoutSku,
    validateVoucherApplicable,
    refreshUserAccount,
    getSubscriptionPaymentDetails,
    doesUserHaveAnActiveSubscription,
    getSubscriptionConfirmationDetails,
    doesUserHaveActiveCreditCards,
    addPaymentMethod
  }
}

export default withApollo(ApiContextProvider)
